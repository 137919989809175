footer{
    background-color: var(--footer-background);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer_container{
    width: var(--width-frame);
    padding: 50px 0 20px 0;
    display: flex;
    justify-content: space-between;
}

.footer_content_left{
    width: 300px;
    text-align: justify;
}


.footer_content_left p{
    font-size: 0.8rem;
    color: rgb(154, 154, 154);
}

.footer_content_left_title{
    margin-top: 0;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    color: white !important;
}

.footer_content_left img {
    width: 30px;
}

.footer_watermark {
    width: var(--width-frame);
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.footer_watermark p{
    font-size: 0.8rem;
    color: rgb(155, 155, 155);
}

footer a img {
    width: 25px;
    margin-left: 20px;
}

.footer_languages{
    width: var(--width-frame);
    display: flex;
    justify-content: start;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .footer_content_left_title{
        font-size: 1rem !important;
    }

    .footer_container{
        flex-direction: column;
        align-items: start;
    }

    footer a img {
        width: 25px;
        margin: 0 20px 0 0;
    }

    .footer_content_right{
        margin-top: 20px;
    }

}