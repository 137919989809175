.button_contact a {
    padding: 10px 40px;
    background-color: var(--secondary-color);
    color: #333333 !important;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.button_contact *{
    color: #333333 !important;
}