/* Desktop */

@media (min-width: 768px) {
    .navbar-contact {
        background-color: var(--secondary-color);
        color: var(--grey-color) !important;
        padding: 5px 10px !important;
    }

    ul {
        margin: 0;
    }

    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        color: white;
        padding: 20px 0;
        font-weight: 500;
        z-index: 1000;
        transition: transform 0.3s ease-in-out; /* Transition for smooth movement */
    }

    .navbar-hidden {
        transform: translateY(-100%); /* Hide the navbar */
    }

    .navbar-inner {
        width: var(--width-frame);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-pages {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-pages a {
        color: white;
        padding-right: 20px;
    }

    .navbar-toggle {
        display: none;
    }

}

/* Mobile */

@media (max-width: 768px) {


    :root {
        --ratio: 5px;
    }

    .navbar-toggle {
        width: 5rem;
        height: 2rem;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 20px;
        right: 20px;
        padding: 10px 15px;
        z-index: 1000;
        background-color: var(--secondary-color);
        border: none;
        border-radius: 100px;
        cursor: pointer;
        color: var(--grey-color);
        font-weight: 700;
        transition: 0.8s cubic-bezier(0.97, 0.66, 0.36, 1.49);
    }

    .navbar-toggle:hover {
        scale: 1.03;
    }

    .navbar-toggle.active:hover {
        scale: 1;
    }

    .navbar-logo {
        display: none;
    }

    .navbar {
        position: fixed;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: start;
        z-index: 1000;
    }

    .navbar-inner {
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: var(--border-radius-big);
        padding: 0;
        margin: 15px;
        transition: 0.8s ease-in-out;
        width: calc(5rem + 10px);
        height: calc(2rem + 10px);
        overflow: hidden;
    }

    :root {
        --border-radius-multiply: 2;
    }

    .navbar-inner.active{
        border-radius: calc(var(--border-radius-big) * var(--border-radius-multiply)) 23px calc(var(--border-radius-big) * var(--border-radius-multiply)) calc(var(--border-radius-big) * var(--border-radius-multiply));
        padding: 80px 0;
        opacity: 1;
        width: calc(100% - 20px);
        height: 15rem;
    }

    .navbar-pages {
        opacity: 0;
        top: 50px;
        right: 200px;
        padding: 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        transition: 0.8s cubic-bezier(0.28, 0.91, 0.41, 1.41);
    }
    
    .navbar-inner.active .navbar-pages {
        transition: 0.8s cubic-bezier(1, 0.02, 1, 0.91);
        opacity: 1;
        padding: 50px 0;
    }

    .navbar-pages li {
        display: none;
        margin: 10px 0;
        font-size: 2rem;
        font-weight: 500;
    }

    .navbar-inner.active .navbar-pages li {
        display: flex;
    }

    .navbar-pages a {
        transform: 0.4s ease-in-out;
    }

    .navbar-pages a:hover {
        color: var(--secondary-color);
    }
}