.section_cover {
    position: relative;
    background-color: white;
    height: 600px;
    border-radius: 0 0 var(--border-radius-big) var(--border-radius-big);
    overflow: hidden;
    background-image: url("/public/img/section_cover.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-direction: column;
}

.section_cover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    z-index: 1;
    pointer-events: none;
}

.section_cover_message-bar_container,
.section_cover_message-bar,
.section_cover-scroll {
    position: relative;
    z-index: 2; /* Assurez-vous que le contenu est au-dessus de l'overlay */
}

.section_cover_message-bar_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 500px;
}

.section_cover_message-bar_title {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    margin: 0;
}

.section_cover_message-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
    background-color: white;
    border-radius: 100px;
    border: none;
}

:root {
    --Button-width: 80px;
    --Message-bar-height: 20px;
}

.section_cover_message-bar input{
    background-color: white;
    height: var(--Message-bar-height);
    color: grey;
    margin: auto;
    margin-top: 0;
    width:  calc(500px - var(--Button-width));
    border-radius: 100px 0 0 100px;
    border: none;
    padding: 12px 20px;
}

.section_cover_message-bar button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--secondary-color);
    margin-top: 0;
    width:  var(--Button-width);
    border-radius: 100px;;
    margin: 5px;
    height: calc(100% - 10px);
    border: 2px solid var(--grey-color);
}

.section_cover_message-bar button img{
    width: 30px;
}
.section_cover-scroll {
    margin: 20px auto;
    margin-top: 210px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}

.section_cover-scroll-arrow{
    width: 10px;
    margin-left: 10px;
}

.section_cover_message-bar_icon {
    width: 50px;
    height: 50px;
    position: relative;
    left: -1rem;
    top:  -1rem;
}


@media screen and (max-width: 768px) {
    
    .section_cover_message-bar_title {
        font-size: 1.5rem;
    }

    .section_cover_message-bar_container {
        width: auto;
        margin: 0 calc(50vw - 20vw - 4rem);
    }

    .section_cover_message-bar input {
        width: 40vw;
    }
}
