.section_design {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section_design_container {
    width: var(--width-frame);
}

.section_design_container img{
    width: 100%;
}

@media screen and (max-width: 768px) {
    .section_design_container {
        width: 100%;
        margin: auto 10px;
    }
}
