.section_techno_container {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, #333333 0%, rgba(0,0,0,0.6) 50% , rgba(0,0,0,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 0 0;
    position: relative;
    top: -60px;
    z-index: -1;
}

.section_techno_container-title_container {
    width: var(--width-frame);
}

.section_techno_container-title {
    margin: 100px auto 0 auto !important;
    font-size: var(--section-title-size);
    font-weight: 600;
    color: white;
    margin-bottom: 20px;
}

.section_techno_containe-subtitle {
    color: var(--grey-light-color); 
}

.section_techno_container-icon_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    padding: 50px 0;
}

.section_techno_container-icon_container div{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
}

.section_techno_container-icon_container img{
    height: 40px;
    margin: 10px;
}

@media screen and (max-width: 768px){

    .section_techno_container-title_container {
        width: calc(100% - 60px);
    }

    .section_techno_container-icon_container img{
        height: 30px;
    }

    .section_techno_container-icon_container{
        flex-direction: column;
    }

    .section_techno_container-icon_container div{
        margin: 20px 0;
    }
}