.LanguageSwitcher_container {
    display: flex;
    align-items: flex-end;
    justify-content: start;
}

.LanguageSwitcher_container button {
    background-color: none !important;
    border: none;
    background-color: transparent !important;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
}

.LanguageSwitcher_container img {
    width: 25px;
    margin: 5px 5px 5px 0;
}