.section_contact_form * {
    color: var(--grey-color);
}

.section_contact_form {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_contact_form_container {
    width: var(--width-frame);
}

.section_contact_form_container_title {
    font-size: var(--section-title-size);
    font-weight: var(--section-title-weight);
    padding: 80px 0 0 0;
}

.section_contact_form_container_subtitle {
    font-size: calc(var(--section-title-size) - 1.3rem);
    font-weight: var(--section-subtitle-weight);
    margin-bottom: 50px;
}

.section_contact_form_container_subtitle span {
    font-weight: 700;
    font-size: calc(var(--section-subtitle-size) + 0.2rem);
}

.section_contact_form_container_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 80px 0;
}

.section_contact_form_container_form input,
textarea,
label {
    width: calc(100% - 40px);
    background-color: rgb(237, 237, 237);
    border: none;
    border-radius: 1.5rem;
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 1rem;
}

label {
    width: auto;
}

textarea {
    height: 150px;
}

textarea:focus,
input:focus {
    outline: solid 2px var(--secondary-color);
}

.section_contact_form_container_form button {
    background-color: var(--secondary-color);
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    font-weight: 700;
    align-items: start;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
}

.section_contact_form_container_form button img {
    width: 20px;
    margin: 0 0 0 8px;
    position: relative;
    top: 2px;
}

.section_contact_form_container_form_checkbox {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked+label {
    background-color: var(--secondary-color);
}

label {
    cursor: pointer;
    margin-right: 15px;
}

@media screen and (max-width: 768px) {

    .section_contact_form_container_form input,
    textarea {
        width: calc(100% - 40px) !important;
    }

    .section_contact_form_container {
        width: 100%;
    }

    .section_contact_form_container_title {
        padding: 30px 0 0 30px;
    }

    .section_contact_form_container_subtitle {
        padding: 0 0 0 30px;
    }

    .section_contact_form_container_form {
        padding: 0 30px 30px 30px;
    }

    .section_contact_form_container_form_checkbox {
        overflow-x: auto;
        scrollbar-width: none;
    }

    .section_contact_form_container_form_checkbox_frame {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

}