.section_contact * {
    color: var(--grey-color);
}

.section_contact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.section_contact_container {
    display: flex;
    flex-direction: column;
    width: var(--width-frame);
    background-color: white;
    border-radius: var(--border-radius-big);
    padding-bottom: calc(80px - 4rem);
}

.section_contact_container_title {
    font-size: var(--section-title-size);
    font-weight: var(--section-title-weight);
    margin: 0;
    padding:  80px 0 0 80px;
}

.section_contact_container_subtitle {
    font-weight: 300;
    font-size: calc(var(--section-title-size) - 1.3rem);
    margin: 0;
    padding:  0 0 0 80px;
}

.section_contact_container_subtitle span {
    font-weight: 700;
}

.section_contact_container_2 {
    display: flex;
    justify-content: space-between;
    padding: 80px 80px 50px 80px;
}

.section_contact_container_2_job_subtitle {
    color: var(--grey-light-color);
}

@media screen and (max-width: 768px) {
    .section_contact {
        width: 100%;
    }

    .section_contact_container {
        width: 100%;
        margin: auto 10px;
    }

    .section_contact_container_2 {
        flex-direction: column;
        padding: 30px 20px;
    }

    .section_contact_container_title {
        padding:  30px 20px;
    }

    .section_contact_container_subtitle {
        padding:  0 0 0 20px;
    }

    .section_contact_container_2_job_subtitle {
        margin-bottom: 50px;
    }

}