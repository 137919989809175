.section_webapp *{
    color: var(--grey-color);
}

.section_webapp {
    display: flex;
    justify-content: center;
    padding: 120px 0 80px 0;
    background-color: white;
    border-radius: var(--border-radius-big);
    margin: 0;
}

.section_webapp_container {
    width: var(--width-frame);
    display: flex;
    flex-direction: column;
}

.section_web_container_title img {
    width: 600px;
}

.section_web_container_sub-title div{
    font-size: 1rem;
    font-weight: 500;
    margin: 20px 0;
    margin-bottom: 50px;
    color: var(--grey-light-color);
}

.section_web_container_text-container {
    display: flex;
    justify-content: space-between;
    text-align: justify;
}

.section_web_container_text-container div {
    color: var(--grey-color);
}

.section_web_container_text-container_content_title {
    color: var(--grey-color) !important;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 20px;
}

.section_web_container_text-container_content-left {
    margin-right: 50px;
}

.section_web_container_graph img{
    margin-top: 50px;
    width: 200px;
    position: relative;
    left: -100px;
}

@media screen and (max-width: 768px) {

    
.section_webapp_container {
   width: calc(100% - 60px);
}


    .section_webapp {
        width: 100%;
    }

    .section_web_container_title img {
        width: 90%;
    }

    .section_web_container_text-container {
        flex-direction: column;
    }
    
    .section_web_container_text-container_content-left {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .section_web_container_graph img{
        width: 150px;
        position: static;
        margin: 50px auto 0 auto;
    }
}