.section_about {
    display: flex;
    flex-direction: column;
    margin: 150px auto;
    background-color: var(--dark-background);
    border-radius: var(--border-radius-big);
    max-width: 1000px;
}

.section_about-container {
    padding: 80px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
}

.section_about-container div {
    display: flex;
    flex-direction: column;
}

.section_about-container_left {
    white-space: nowrap;
}

.section_about-container_left_title {
    font-size: var(--section-title-size);
    font-weight: var(--section-title-weight);
    margin-bottom: 30px;
}

.section_about-container_left_job-title {
    color: var(--grey-light-color);
}

.section_about-container_right {
    margin: 0 50px;
    max-width: 500px;
    display: flex;
    text-align: justify;
    align-items: end !important;
    justify-content: space-around;
}

.section_about-container_right_btn {
    display: flex;
    justify-content: end;
    margin: 50px 50px;
    padding: 0 80px;
}


@media screen and (max-width: 768px) {

    .section_about {
        margin: 100px 10px;
    }
    
    .section_about-container {
        flex-direction: column;
        padding: 50px;
    }
    
    .section_about-container_right {
        margin: 0;
        margin-top: 50px;
        max-width: none ;
    }

    .section_about-container_right_btn {
        justify-content: center;
    }
}