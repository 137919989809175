.cookie_container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1000;
}

.cookie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 5rem;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.464);
    backdrop-filter: blur(5px);
    z-index: 2000;
    border-radius: 100px;
    border: 2px solid white;
    animation: appear 3s reverse ease-in-out;
}

@keyframes appear {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-300px);
    }
}

@keyframes desapear {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0);
    }
    60% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-300px);
    }
}

.cookie p {
    color: white;
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 20px;
    white-space: nowrap;
}

.cookie img {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 10px 0 0;
    opacity: 0.5;
}

.delete {
    animation: desapear 3s forwards ease-in-out;
}