:root {
    --chat-size: 50px;
}


.app_chat img{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: var(--chat-size);
    height: var(--chat-size);
    bottom: 40px;
    right: 100px;
    cursor: pointer;
    z-index: 1000;
}

@media screen and (max-width: 768px) {
    .app_chat img{
        right: 20px;
        bottom: 20px;
    }
    
}