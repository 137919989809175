.section_workflow * {
    color: var(--grey-color);
}

.section_workflow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}

.section_workflow-container {
    width: var(--width-frame);
    background-color: white;
    border-radius: var(--border-radius-big);
    padding-bottom: calc(80px - 4rem);
}

.section_workflow_container-title {
    font-size: var(--section-title-size);
    font-weight: var(--section-title-weight);
    margin: 0;
    padding:  80px 0 0 80px;
}

.section_workflow_container-subtitle {
    color: var(--grey-light-color);
    font-weight: var(--sevtion-subtitle-weight);
    font-size: var(--section-subtitle-size);
    margin: 0;
    padding:  0 0 0 80px;
}

.section_workflow_container-graph {
    position: relative;
    top: -4rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 80px;
}

.section_workflow_container-graph img{
    width: calc((100% / 4) - (1%));
}

@media screen and (max-width: 768px) {
    .section_workflow {
        width: 100%;
    }

    .section_workflow-container {
        width: 100%;
        margin: auto 10px;
    }

    .section_workflow_container-graph {
        padding: 30px 20px;
        position: static;
    }

    .section_workflow_container-title {
        padding:  30px 0 0 20px;
    }

    .section_workflow_container-subtitle {
        padding:  0 0 0 20px;
    }
}