* {
  color: white;
  list-style-type: none;
  text-decoration: none;
  font-family: "Red Hat Display", sans-serif;
}


input:focus {
  outline: none;
}

body {
  margin: 0;
  background-color: var(--grey-color);
  height: 200vh;
}

:root {
  --primary-color: #3498db;
  --secondary-color: #F1FF53;
  --grey-color: #333333;
  --grey-light-color: #757575;
  --dark-background: #191919;
  --footer-background: #0e0e0e;
  --width-frame: min(80vw, 1100px);
  --border-radius-big: 30px;
  --section-title-size: 3rem;
  --section-title-weight: 600;
  --section-subtitle-size: 1.2rem;
  --section-subtitle-weight: 300;
}

@media screen and (max-width: 768px) {
  :root {
    --section-title-size: 2.3rem;
    --width-frame: calc(100vw - 60px);
  }

  * .mobile-none {
    display: none !important;
  }
  
}